//映射表
export default {
  manageMenuMapper: {
    //概况
    1000: {
      path: '/general',
    },
    //任务
    1001: {
      path: '/task'
    },
    //数据
    1005: {
      path: '/statistic',
    },
    //服务管理
    1003001: {
      path: '/service/serviceManage',
    },
    //服务审核
    1003002: {
      path: '/service/serviceCheck',
    },
    //项目管理
    1003003: {
      path: '/service/projectManage',
    },
    //类目管理
    1003004: {
      path: '/service/categoryManage'
    },
    //待处理提现
    1004001: {
      path: '/cashOut/list/index',
      inner: true
    },
    //已完成提现
    1004002: {
      path: '/cashOut/list/finish',
      inner: true
    },
    //拒绝提现
    1004003: {
      path: '/cashOut/list/refuse',
      inner: true
    },
    //通用设置
    1006001: {
      path: '/setting/common',
    },
    //店铺设置
    1006002: {
      path: '/setting/shop',
    },
    //交易设置
    1006003: {
      path: '/setting/tradeSetting',
    },
    //用户继承
    1006004: {
      path: '/setting/changeSales',
    },
    //员工管理
    1002001001: {
      path: '/staff/staffList/staffList',
      inner: true
    },
    //角色管理
    1002001002: {
      path: '/staff/staffList/actorList',
      inner: true
    },
    //已禁用账号
    1002001003: {
      path: '/staff/staffList/forbid',
      inner: true
    },
    //推广员账户
    1007001001: {
      path: '/promoter/list/page',
      inner: true
    },
    //已禁用账号
    1007001002: {
      path: '/promoter/list/forbit',
      inner: true
    },
    //控制台概况
    1007002001: {
      path: '/promoter/console/index',
      inner: true
    },
    //客户列表
    1007002002: {
      path: '/promoter/console/customer',
      inner: true
    },
    //收益明细
    1007002003: {
      path: '/promoter/console/record',
      inner: true
    },
    //车险计算器
    1008: {
      path: '/util',
    },
    //车险计算器
    1008003: {
      path: '/util/calculator',
    },
    //营业部列表
    1008001: {
      path: '/util/sales/page',
    }
  }
}
