import Vue from 'vue'
import Router from 'vue-router'
import $x from '../libs/$x'

import shopRouter from './modules/shop'

//同步加载，合并打包
Vue.use(Router)

const vueRouter = Router.prototype.push


//同步加载，合并打包
Router.prototype.push = function push(location) {
  return vueRouter.call(this, location).catch(err => err)
}

// 组件懒加载：组件会被webpack打包多个js，当路由被访问的时候只加载相应组件js
const Test = resolve => require(['../views/test.vue'], resolve);
const Layer = resolve => require(['../views/layer/layer.js'], resolve);
const Login = resolve => require(['../views/login/login.js'], resolve);
const ForgetPassword = resolve => require(['../views/forget/index.js'], resolve);

export const commonRoutes = [{
  path: '/login',
  name: 'login',
  component: Login
},{
  path: '/forget',
  name: 'forget',
  component: ForgetPassword
},
{
  path: '/',
  component: Layer,
},
{
  path: '/test',
  component: Test
}
]

export const shopRoutes = shopRouter

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: commonRoutes
})

const router = createRouter()

const whiteList = ['/login','/forget']

router.beforeEach(async (to, from, next) => {
  // iView.LoadingBar.start();
  const safeAim = whiteList.some(inner => {
    if (to.path.startsWith(inner)) {
      return true
    }
  })
  if (safeAim) {
    next()
    return
  }

  let userInfo = $x.localStorage.getItem('USER_INFO');
  if (!userInfo) {
    next({
      name: 'login'
    });
    return
  }

  //仅用于初步开发阶段调试
  let storeRouter = router.app.$options.store.state.routes.routes
  if (storeRouter.length == 0) {
    let nextPath = null
    await router.app.$options.store.dispatch('routes/initRouter', userInfo)
    if (to.path === '/') {
      if (router.app.$options.store.state.routes.addRoutes.length > 0) {
        router.app.$options.store.state.routes.addRoutes.some(item => {
          if (item.meta.show) {
            nextPath = item.path
            return true
          }
          return false
        })
      }
    }

    // if (!to.path.startsWith('/general')) {
    //   let shopId = $x.localStorage.getItem('SHOP_ID');
    //   if (!shopId) {
    //     router.app.$options.store.state.user.shopId = null
    //     next({
    //       name: 'general'
    //     });
    //     return
    //   }
    // }

    if (nextPath) {
      next({
        path: nextPath,
        replace: true
      })
    } else {
      // 刷新页面时
      next({
        path: to.path,
        query: to.query,
        params: to.params
      })
    }
  }
  next()
});

router.afterEach(to => {
  // iView.LoadingBar.finish();
  window.scrollTo(0, 0);
});


// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router;
