const Layer = resolve => require(['@/views/layer/layer.js'], resolve);

//概况
const General = resolve => require(['@/views/shop/general'], resolve);
//任务
const Task = resolve => require(['@/views/shop/task'], resolve);
//任务新增
const AddTask = resolve => require(['@/views/shop/taskAdd'], resolve);
//任务编辑
const EditTask = resolve => require(['@/views/shop/taskEdit'], resolve);
//任务详情
const DetailTask = resolve => require(['@/views/shop/taskDetail'], resolve);

//员工列表Layer
const StaffListLayer = resolve => require(['@/views/shop/staffListLayer'], resolve);
//员工列表
const StaffList = resolve => require(['@/views/shop/staffList'], resolve);
//添加员工
const StaffAdd = resolve => require(['@/views/shop/staffAdd'], resolve);
//员工详情
const StaffDetail = resolve => require(['@/views/shop/staffDetail'], resolve);
//员工管理
const StaffEdit = resolve => require(['@/views/shop/staffEdit'], resolve);
//修改密码
const StaffPwd = resolve => require(['@/views/shop/staffPwd'], resolve);
//角色管理列表
const ActorList = resolve => require(['@/views/shop/actorList'], resolve);
//新增角色
const ActorAdd = resolve => require(['@/views/shop/actorAdd'], resolve);
//编辑角色
const ActorEdit = resolve => require(['@/views/shop/actorEdit'], resolve);
//角色详情
const ActorDetail = resolve => require(['@/views/shop/actorDetail'], resolve);
//已禁用员工管理
const StaffForbidEdit = resolve => require(['@/views/shop/staffForbidEdit'], resolve);
//已禁用账号
const StaffForbid = resolve => require(['@/views/shop/staffForbid'], resolve);

//服务管理
const ServiceManage = resolve => require(['@/views/shop/serviceManage'], resolve);
//新增服务
const ServiceAdd = resolve => require(['@/views/shop/serviceAdd'], resolve);
//编辑服务
const ServiceEdit = resolve => require(['@/views/shop/serviceEdit'], resolve);
//服务详情
const ServiceMessage = resolve => require(['@/views/shop/serviceMessage'], resolve);
//服务审核
const ServiceCheck = resolve => require(['@/views/shop/serviceCheck'], resolve);
//服务审核
const ServiceCheckDetail = resolve => require(['@/views/shop/serviceCheckDetail'], resolve);
//项目管理
const ProjectManage = resolve => require(['@/views/shop/projectManage'], resolve);
//新增项目
const ProjectAdd = resolve => require(['@/views/shop/projectAdd'], resolve);
//编辑项目
const ProjectEdit = resolve => require(['@/views/shop/projectEdit'], resolve);
//项目详情
const ProjectDetail = resolve => require(['@/views/shop/projectDetail'], resolve);
//类目管理
const CategoryManage = resolve => require(['@/views/shop/categoryManage'], resolve);
//新增类目
const CategoryAdd = resolve => require(['@/views/shop/categoryAdd'], resolve);
//编辑类目
const CategoryEdit = resolve => require(['@/views/shop/categoryEdit'], resolve);
//类目详情
const CategoryDetail = resolve => require(['@/views/shop/categoryDetail'], resolve);

//数据
const Statistic = resolve => require(['@/views/shop/statistic'], resolve);
//数据
const StatisticDetail = resolve => require(['@/views/shop/statisticDetail'], resolve);

//通用设置
const CommonSetting = resolve => require(['@/views/shop/commonSetting'], resolve);
//店铺设置
const ShopSetting = resolve => require(['@/views/shop/shopSetting'], resolve);
//交易设置
const TradeSetting = resolve => require(['@/views/shop/tradeSetting'], resolve);
//编辑门店
const EditShopInfo = resolve => require(['@/views/shop/shopSetting/edit'], resolve);
//店铺认证引导
const ShopAuthGuide = resolve => require(['@/views/shop/shopSetting/authGuide'], resolve);
//店铺认证
const ShopAuth = resolve => require(['@/views/shop/shopSetting/auth'], resolve);

//推广员控制台容器
const PromoterConsoleLayer = resolve => require(['@/views/shop/promoterConsoleLayer'], resolve);
//推广员列表容器
const PromoterListLayer = resolve => require(['@/views/shop/promoterListLayer'], resolve);
//推广员列表
const PromoterList = resolve => require(['@/views/shop/promoterList'], resolve);
//新增推广员
const PromoterAdd = resolve => require(['@/views/shop/promoterAdd'], resolve);
//推广员详情
const PromoterDetail = resolve => require(['@/views/shop/promoterDetail'], resolve);
//编辑推广员
const PromoterEdit = resolve => require(['@/views/shop/promoterEdit'], resolve);
//推广员控制台
const PromoterConsole = resolve => require(['@/views/shop/promoterConsole'], resolve);
//推广员客户
const PromoterCustomer = resolve => require(['@/views/shop/promoterCustomer'], resolve);
//禁止推广员列表
const PromoterForbitList = resolve => require(['@/views/shop/promoterForbitList'], resolve);
//编辑推广员
const PromoterForbitEdit = resolve => require(['@/views/shop/promoterForbitEdit'], resolve);
//推广员推广额记录
const PromoterRecord = resolve => require(['@/views/shop/promoterRecord'], resolve);
//推广员客户详情
const PromoterCustomerDetail = resolve => require(['@/views/shop/customerDetail'], resolve);

//提现容器
const CashOutLayer = resolve => require(['@/views/shop/cashOutLayer'], resolve);
//提现列表
const CashOutList = resolve => require(['@/views/shop/cashOutList'], resolve);
//已完成提现
const CashOutFinish = resolve => require(['@/views/shop/cashOutFinish'], resolve);
//拒绝提现
const CashOutRefuse = resolve => require(['@/views/shop/cashOutRefuse'], resolve);

//用户继承
const ChangeSales = resolve => require(['@/views/shop/changeSales'], resolve);
//汽车保险利润计算器
const CalculatorCar = resolve => require(['@/views/shop/calculatorCar'], resolve);

//创建营业部Layer
const SalesOfficeLayer = resolve => require(['@/views/shop/salesOfficeLayer'], resolve);
//营业部Page
const SalesOfficePage = resolve => require(['@/views/shop/salesOfficePage'], resolve);
//营业部Page
const SalesOfficeDetail = resolve => require(['@/views/shop/salesOfficeDetail'], resolve);


const MyInfo = resolve => require(['@/views/shop/myInfo'], resolve);

//店铺列表
const ShopList = resolve => require(['@/views/shop/shopList'], resolve);
//创建编辑店铺
const CreateShop = resolve => require(['@/views/shop/createShop'], resolve);

/**
 * 多级子路由在Layer的children后面继续添加 不需要再在children中嵌套children
 */

const shopRouter = [
  {
    path: '/general',
    meta: {
      title: '概况',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      show: false
    },
    redirect: '/general/index',
    component: Layer,
    children: [{
      path: '/general/index',
      name: 'general',
      component: General,
      meta: {
        title: '概况',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/task',
    meta: {
      title: '任务',
      icon: 'iconfont al-icon-renwuzhongxin',
      icon_m: 'iconfont al-icon-renwuzhongxin',
      show: false
    },
    redirect: '/task/index',
    component: Layer,
    children: [{
      path: '/task/index',
      name: 'task',
      component: Task,
      meta: {
        title: '任务',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/task/addTask',
      name: 'addTask',
      component: AddTask,
      meta: {
        title: '新建任务',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/task/editTask',
      name: 'editTask',
      component: EditTask,
      meta: {
        title: '编辑任务',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/task/taskDetail',
      name: 'taskDetail',
      component: DetailTask,
      meta: {
        title: '任务详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/staff',
    meta: {
      title: '员工',
      icon: 'iconfont al-icon-yuangong',
      icon_m: 'iconfont al-icon-yuangong',
      show: false
    },
    redirect: '/staff/staffList',
    component: Layer,
    children: [{
      path: '/staff/staffList',
      name: 'staffListLayer',
      redirect: '/staff/staffList/staffList',
      component: StaffListLayer,
      meta: {
        title: '员工列表',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      },
      children: [
        {
          path: '/staff/staffList/staffList',
          name: 'staffList',
          component: StaffList,
          meta: {
            title: '员工列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/staff/staffList/staffList/staffAdd',
          name: 'staffAdd',
          component: StaffAdd,
          meta: {
            title: '添加员工',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffList/staffDetail',
          name: 'staffDetail',
          component: StaffDetail,
          meta: {
            title: '员工详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffList/staffEdit',
          name: 'staffEdit',
          component: StaffEdit,
          meta: {
            title: '员工编辑',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/staffList/staffPwd',
          name: 'staffPwd',
          component: StaffPwd,
          meta: {
            title: '修改密码',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList',
          name: 'actorList',
          component: ActorList,
          meta: {
            title: '角色管理列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList/actorAdd',
          name: 'actorAdd',
          component: ActorAdd,
          meta: {
            title: '新增角色',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList/actorEdit',
          name: 'actorEdit',
          component: ActorEdit,
          meta: {
            title: '编辑角色',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/actorList/actorDetail',
          name: 'actorDetail',
          component: ActorDetail,
          meta: {
            title: '角色详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        },
        {
          path: '/staff/staffList/forbid',
          name: 'staffForbid',
          component: StaffForbid,
          meta: {
            title: '已禁用账号',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/staff/staffList/forbid/edit',
          name: 'staffForbidEdit',
          component: StaffForbidEdit,
          meta: {
            title: '已禁用账号编辑',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
    }
    ]
  },
  {
    path: '/service',
    meta: {
      title: '服务',
      icon: 'iconfont al-icon-neirongmoxingshezhi',
      icon_m: 'iconfont al-icon-neirongmoxingshezhi',
      show: false
    },
    redirect: '/service/serviceManage',
    component: Layer,
    children: [{
      path: '/service/serviceManage',
      name: 'serviceManage',
      component: ServiceManage,
      meta: {
        title: '服务管理',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      },
    }, {
      path: '/service/serviceManage/add',
      name: 'serviceAdd',
      component: ServiceAdd,
      meta: {
        title: '新增服务',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/serviceManage/edit',
      name: 'serviceEdit',
      component: ServiceEdit,
      meta: {
        title: '编辑服务',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/serviceManage/message',
      name: 'serviceMessage',
      component: ServiceMessage,
      meta: {
        title: '服务详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/serviceCheck',
      name: 'serviceCheck',
      component: ServiceCheck,
      meta: {
        title: '服务审核',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/serviceCheck/Detail',
      name: 'serviceCheckDetail',
      component: ServiceCheckDetail,
      meta: {
        title: '审核详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/projectManage',
      name: 'projectManage',
      component: ProjectManage,
      meta: {
        title: '项目管理',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/projectManage/add',
      name: 'projectAdd',
      component: ProjectAdd,
      meta: {
        title: '新增项目',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/projectManage/edit',
      name: 'projectEdit',
      component: ProjectEdit,
      meta: {
        title: '编辑项目',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/projectManage/detail',
      name: 'projectDetail',
      component: ProjectDetail,
      meta: {
        title: '项目详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/categoryManage',
      name: 'categoryManage',
      component: CategoryManage,
      meta: {
        title: '类目管理',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/categoryManage/add',
      name: 'categoryAdd',
      component: CategoryAdd,
      meta: {
        title: '新增类目',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/categoryManage/edit',
      name: 'categoryEdit',
      component: CategoryEdit,
      meta: {
        title: '编辑类目',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/service/categoryManage/detail',
      name: 'categoryDetail',
      component: CategoryDetail,
      meta: {
        title: '类目详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }
    ]
  },
  {
    path: '/cashOut',
    meta: {
      title: '提现',
      icon: 'iconfont al-icon-qianbao',
      icon_m: 'iconfont al-icon-qianbao',
      show: false
    },
    redirect: '/cashOut/list',
    component: Layer,
    children: [{
      path: '/cashOut/list',
      name: 'cashOutListLayer',
      redirect: '/cashOut/list/index',
      component: CashOutLayer,
      meta: {
        title: '提现',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      },
      children: [{
        path: '/cashOut/list/index',
        name: 'cashOutList',
        component: CashOutList,
        meta: {
          title: '提现列表',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }, {
        path: '/cashOut/list/finish',
        name: 'cashOutFinish',
        component: CashOutFinish,
        meta: {
          title: '已完成提现',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }, {
        path: '/cashOut/list/refuse',
        name: 'cashOutRefuse',
        component: CashOutRefuse,
        meta: {
          title: '拒绝提现',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }]
    }]
  },
  {
    path: '/statistic',
    meta: {
      title: '数据',
      icon: 'iconfont al-icon-shuju1',
      icon_m: 'iconfont al-icon-shuju1',
      show: false
    },
    redirect: '/statistic/index',
    component: Layer,
    children: [{
      path: '/statistic/index',
      name: 'statistic',
      component: Statistic,
      meta: {
        title: '数据',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/statistic/detail',
      name: 'statisticDetail',
      component: StatisticDetail,
      meta: {
        title: '数据详情',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/setting',
    meta: {
      title: '设置',
      icon: 'iconfont al-icon-shezhi',
      icon_m: 'iconfont al-icon-shezhi',
      show: false
    },
    redirect: '/setting/common',
    component: Layer,
    children: [{
      path: '/setting/common',
      name: 'commonSetting',
      component: CommonSetting,
      meta: {
        title: '通用设置',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/setting/shop',
      name: 'shopSetting',
      component: ShopSetting,
      meta: {
        title: '店铺设置',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/setting/tradeSetting',
      name: 'tradeSetting',
      component: TradeSetting,
      meta: {
        title: '交易设置',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/setting/shop/editShopInfo',
      name: 'editShopInfo',
      component: EditShopInfo,
      meta: {
        title: '编辑门店',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/setting/shop/shopAuthGuide',
      name: 'shopAuthGuide',
      component: ShopAuthGuide,
      meta: {
        title: '店铺认证引导',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }, {
      path: '/setting/shop/shopAuth',
      name: 'shopAuth',
      component: ShopAuth,
      meta: {
        title: '店铺认证',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    },{
      path: '/setting/changeSales',
      name: 'changeSales',
      component: ChangeSales,
      meta: {
        title: '用户继承',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  },
  {
    path: '/promoter',
    meta: {
      title: '推广员',
      icon: 'iconfont al-icon-tuiguang',
      icon_m: 'iconfont al-icon-tuiguang',
      show: false
    },
    redirect: '/promoter/list',
    component: Layer,
    children: [
      {
        path: '/promoter/list',
        name: 'promoterListLayer',
        redirect: '/promoter/list/page',
        component: PromoterListLayer,
        meta: {
          title: '推广员列表',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        },
        children: [{
          path: '/promoter/list/page',
          name: 'promoterList',
          component: PromoterList,
          meta: {
            title: '推广员列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/list/page/add',
          name: 'promoterAdd',
          component: PromoterAdd,
          meta: {
            title: '新增推广员',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/list/page/detail',
          name: 'promoterDetail',
          component: PromoterDetail,
          meta: {
            title: '推广员详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/list/page/edit',
          name: 'promoterEdit',
          component: PromoterEdit,
          meta: {
            title: '编辑推广员',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/list/forbit',
          name: 'promoterForbitList',
          component: PromoterForbitList,
          meta: {
            title: '禁止推广员列表',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/list/forbit/edit',
          name: 'promoterForbitEdit',
          component: PromoterForbitEdit,
          meta: {
            title: '禁止推广员列表编辑',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
      },
      {
        path: '/promoter/console',
        name: 'promoterConsoleLayer',
        redirect: '/promoter/console/index',
        component: PromoterConsoleLayer,
        meta: {
          title: '推广员控制台',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        },
        children: [{
          path: '/promoter/console/index',
          name: 'promoterConsole',
          component: PromoterConsole,
          meta: {
            title: '推广员控制台',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/customer',
          name: 'promoterCustomer',
          component: PromoterCustomer,
          meta: {
            title: '推广员客户',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/customer/detail',
          name: 'promoterCustomerDetail',
          component: PromoterCustomerDetail,
          meta: {
            title: '推广员客户详情',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }, {
          path: '/promoter/console/record',
          name: 'promoterRecord',
          component: PromoterRecord,
          meta: {
            title: '推广收益明细',
            icon: 'el-icon-menu',
            icon_m: 'md-apps',
            show: false
          }
        }]
      }]
  },
  {
    path: '/util',
    meta: {
      title: '车险计算器',
      icon: 'al-icon-cac',
      icon_m: 'al-icon-cac',
      show: false
    },
    redirect: '/util/calculator',
    component: Layer,
    children: [
      {
        path: '/util/calculator',
        name: 'calculatorCar',
        component: CalculatorCar,
        meta: {
          title: '汽车保险利润计算器',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      },{
        path: '/util/sales/page',
        name: 'salesOfficePage',
        component: SalesOfficePage,
        meta: {
          title: '营业部列表',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }, {
        path: '/util/sales/page/add',
        name: 'salesOfficeAdd',
        component: SalesOfficeLayer,
        meta: {
          title: '新增营业部',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }, {
        path: '/util/sales/page/detail',
        name: 'salesOfficeDetail',
        component: SalesOfficeDetail,
        meta: {
          title: '营业部详情',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }, {
        path: '/util/sales/page/edit',
        name: 'salesOfficeEdit',
        component: SalesOfficeLayer,
        meta: {
          title: '编辑营业部',
          icon: 'el-icon-menu',
          icon_m: 'md-apps',
          show: false
        }
      }]
  },
  {
    path: '/myInfo',
    meta: {
      title: '个人信息',
      icon: 'iconfont al-icon-gaikuang',
      icon_m: 'iconfont al-icon-gaikuang',
      show: false
    },
    redirect: '/myInfo/index',
    component: Layer,
    children: [{
      path: '/myInfo/index',
      name: 'myInfo',
      component: MyInfo,
      meta: {
        title: '个人信息',
        icon: 'el-icon-menu',
        icon_m: 'md-apps',
        show: false
      }
    }]
  }
]

export default shopRouter
